import React, { useEffect, useState, useContext } from 'react'

import payment from '../../resources/paymentCard.json'
import paymentTypes from '../../resources/paymentMethod.json'
import coupons from './coupon.json'
import { CiLaptop } from 'react-icons/ci'
import { FiCreditCard } from 'react-icons/fi'
import { TbWallet } from 'react-icons/tb'
import { FaArrowRight } from 'react-icons/fa6'
import { CiCircleMinus } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import AddOnCard from './addOnCard'
import { useParams } from 'react-router-dom'
import { PostRequest } from '../../resources/api-request'
import PlanDetailsMobile from './plansDetailsMobile'
import CardOptions from './cardOptions'
import PlanpaymentType from './paymentType'
import AddExtra from './addExtras'
import SummaryForm from './summaryForm'
import { useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../store/reducers/store'
import { useSelector } from 'react-redux'
import { submitPlanDetails } from '../../store/reducers/planSlice'
import { updatePlanDetailsStates } from '../../store/actions/planDetails'
import { planDetailsStates } from './planDetailsStates';
import { jwtDecode } from 'jwt-decode';
import { Loader } from '../../components/loader'
import { calculateCharges } from './calculatePaystackCharges'

const PlanDetails = () => {
    const [paymentMethodChoices, setpaymentMethodChoices] = useState<any>([])
    const { plan_id, plan_duration } = useParams<{ plan_id: string; plan_duration: string }>()
    const [subscriptionData, setSubscriptionData] = useState<any>([])
    const [paymentMethod, setPaymentMethod] = useState<string>('')
    const [discount, setDiscount] = useState<string>('0')
    const [subTotal, setSubTotal] = useState<number>(0)
    const [planDetails, setPlanDetails] = useState<any>({})
    const [orderSummary, setOrderSummary] = useState<any>([])
    const [total, setTotal] = useState<number>(subTotal)
    const [paymentGateway, setPaymentGateway] = useState(paymentTypes)
    const dispatch = useDispatch<AppDispatch>()
    const plansSummary = useSelector((state: RootState) => state.planSummarySlice);

    const displaySubTotal = isNaN(subTotal) ? 0 : subTotal
    const displayTotal = isNaN(total) ? 0 : total

    interface MyJwtPayload {
        username?: string;
        location_id?: number;
        name?: string
    }


    const { subAmount = 0, finalTotal = 0, couponDiscount = 0, ordersSummary, loader } = planDetailsStates();

    useEffect(() => {
        let totalPrice = 0
        for (let i = 0; i < ordersSummary.length; i++) {
            totalPrice += parseInt(ordersSummary[i].price)
        }
        const planPrice = plan_duration === 'yearly' ? planDetails?.amountYear : planDetails?.amountMonth;

        setSubTotal(totalPrice + planPrice)
        setTotal(totalPrice + planPrice);
        const token: any = localStorage.getItem('token');
        const decoded: MyJwtPayload = jwtDecode(token);
        // console.log({decoded});

        dispatch(updatePlanDetailsStates({ plan_id, duration: plan_duration === 'yearly' ? 12 : 1, email: decoded.username, location_id: decoded.location_id, name: decoded.name }));
    }, [ordersSummary]);

    const calcDiscount = (amount: number, discount: number) => {
        const val = amount - ((discount / 100) * amount);
        return val
    }

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            const planParam = { plan_id: plan_id, plan_duration: plan_duration === 'yearly' ? 12 : 1 }
            dispatch(submitPlanDetails(planParam))
            // console.log(plansSummary);

            try {
                const response = await PostRequest('subscription-summary', { plan_id: plan_id, plan_duration: plan_duration === 'yearly' ? 12 : 1 })
                console.log(response, 'response');

                if (response.data.success === true) {
                    // Save data to state if the request is successful

                    setSubscriptionData(response.data?.data[0]?.addons)
                    setPlanDetails(response.data?.data[0]?.planDetails[0])
                    setpaymentMethodChoices(response.data?.data[0]?.pCardDetails)
                    // setSubTotal(
                    //     plan_duration === 'yearly' ? response.data?.data[0]?.planDetails[0]?.amountYear : response.data?.data[0]?.planDetails[0]?.amountMonth
                    // )
                    dispatch(updatePlanDetailsStates({
                        // subAmount: plan_duration === 'yearly' ? calcDiscount(response.data?.data[0]?.planDetails[0]?.amountYear, response.data?.data[0]?.planDetails[0]?.discount) : calcDiscount(response.data?.data[0]?.planDetails[0]?.amountMonth, response.data?.data[0]?.planDetails[0]?.discount),
                        // finalTotal: plan_duration === 'yearly' ? calcDiscount(response.data?.data[0]?.planDetails[0]?.amountYear, response.data?.data[0]?.planDetails[0]?.discount) : calcDiscount(response.data?.data[0]?.planDetails[0]?.amountMonth, response.data?.data[0]?.planDetails[0]?.discount),
                        // finalTotals: plan_duration === 'yearly' ? calcDiscount(response.data?.data[0]?.planDetails[0]?.amountYear, response.data?.data[0]?.planDetails[0]?.discount) : calcDiscount(response.data?.data[0]?.planDetails[0]?.amountMonth, response.data?.data[0]?.planDetails[0]?.discount),
                        subAmount: plan_duration === 'yearly' ? response.data?.data[0]?.planDetails[0]?.amountYear : response.data?.data[0]?.planDetails[0]?.amountMonth,
                        planPrice: plan_duration === 'yearly' ? response.data?.data[0]?.planDetails[0]?.amountYear : response.data?.data[0]?.planDetails[0]?.amountMonth,
                        finalTotal: plan_duration === 'yearly' ? calcDiscount(response.data?.data[0]?.planDetails[0]?.amountYear, response.data?.data[0]?.planDetails[0]?.discount) : response.data?.data[0]?.planDetails[0]?.amountMonth,
                        finalTotals: plan_duration === 'yearly' ? calcDiscount(response.data?.data[0]?.planDetails[0]?.amountYear, response.data?.data[0]?.planDetails[0]?.discount) : response.data?.data[0]?.planDetails[0]?.amountMonth,
                        
                        onboardingData: response.data?.data[0]?.onboarding
                    }));
                    // setTotal(
                    //     plan_duration === 'yearly' ? response.data?.data[0]?.planDetails[0]?.amountYear : response.data?.data[0]?.planDetails[0]?.amountMonth
                    // )
                } else {
                    // Handle other status types if needed
                    console.error('Request failed with status:', response.data.status)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        fetchSubscriptionData() // Call the fetch function
    }, [plan_id, plan_duration])


    const handleChange = (x: number, y: number) => {
        // const [pointX, pointY]: [number, number] = [x, y];

        // Create a copy of the plansSummary array
        const updatedPlansSummary = [...subscriptionData]

        // Access the plan summary object at index x
        const edited = updatedPlansSummary[x]

        // Access the id of the option at index y within the options array of the edited plan summary
        const id = edited.options[y].id

        // Update the selected property of the edited plan summary
        edited.selected = id

        // Log the updated plansSummary (optional)
        // console.log(updatedPlansSummary, 'updated')

        // Set the state with the updated plansSummary array
        setSubscriptionData(updatedPlansSummary)

        setOrderSummary((prev: any) => {
            console.log(prev);

            // Check if the object already exists
            const existingIndex = prev.findIndex((item: any) => item.name === edited.name);

            // If it doesn't exist, add it
            if (existingIndex === -1) {
                const plan: any = {
                    name: `${edited.name}`,
                    price: edited.options[y].amount,
                    qty: `${edited.options[y].qty}`,
                    action: true
                }
                dispatch(updatePlanDetailsStates({ ordersSummary: [...prev, plan] }));
                return [...prev, plan]
            } else {
                const updatedPrev = prev.map((item: any, index: number) => {
                    if (index === existingIndex && item.price !== edited.options[y].amount) {
                        // Dispatch update for all orderSummary
                        dispatch(updatePlanDetailsStates({
                            ordersSummary: prev.map((item: any, idx: number) => {
                                if (idx === existingIndex) {
                                    return { ...item, price: edited.options[y].amount, qty: `${edited.options[y].qty}` };
                                }
                                return item;
                            })
                        }));

                        // Return the updated item
                        return { ...item, price: edited.options[y].amount, qty: `${edited.options[y].qty}` };
                    }
                    return item; // Keep the item unchanged
                });
                return updatedPrev;
            }

        })


    }

    const handleDeleteOrderSummary = (name: string, price: string) => {
        const filterOutSummary = ordersSummary.filter((val: any) => !(val.name === name && val.price === price))
        setOrderSummary(filterOutSummary);

        dispatch(updatePlanDetailsStates({ ordersSummary: filterOutSummary }));

        let fT = finalTotal
        let additionalUsers = 0
        let additionalOutlets = 0
        let additionalProducts = 0


        const orderAmount = filterOutSummary.reduce((acc: number, item: any) => {
            return acc + item.price
        }, 0);

        fT = orderAmount + finalTotal;

        filterOutSummary.map((item: any) => {
            if (item.name == 'Additional Active Users') {
                additionalUsers = item.qty
            } else if (item.name == 'Additional Unique Outlets') {
                additionalOutlets = item.qty
            } else if (item.name == 'Additional Unique Products') {
                additionalProducts = item.qty
            }
        });

        dispatch(updatePlanDetailsStates({ finalTotals: fT, additionalUsers, additionalOutlets, additionalProducts }));

        // Find and reset the corresponding item in subscriptionData
        const updatedSubscriptionData = subscriptionData.map((plan: any) => {
            // Assuming each plan has a 'name' and 'options' array with 'amount'
            if (plan.name === name) {
                // Reset the selected state or any other necessary properties
                plan.selected = null; // Assuming 'selected' needs to be reset. Adjust based on your actual data structure.
                // Reset other properties as needed
            }
            return plan;
        });
        setSubscriptionData(updatedSubscriptionData);

        console.log(filterOutSummary, 'Filter deleted')


    }

    // console.log({finalTotals});

    const handleCoupon = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscount(event.target.value)
        dispatch(updatePlanDetailsStates({ coupon: event.target.value }));
    }

    const applyCoupon = async () => {

        try {
            if (couponDiscount === 0) {
                dispatch(updatePlanDetailsStates({ loader: true }));

                const response = await PostRequest('check-coupon', { amount: finalTotal, coupon: discount });

                alert(response.data.message);
                if (!response.data.error) {
                    const totals = finalTotal - subAmount;

                    dispatch(updatePlanDetailsStates({
                        subAmount: response.data.data.newAmount,
                        couponDiscount: response.data.data.amount_discounted,
                        finalTotal: totals + response.data.data.newAmount,
                        finalTotals: totals + response.data.data.newAmount,

                    }));
                }
                dispatch(updatePlanDetailsStates({ loader: false }))
            } else {
                dispatch(updatePlanDetailsStates({ loader: false }))
                alert('You already used a coupon');
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, id: number, cardType: string) => {

        dispatch(updatePlanDetailsStates({ card_type: cardType }));
        if (paymentMethodChoices) {
            const updatedPlansPayment = paymentMethodChoices.map((payment: any) => {
                if (payment.id === id) {
                    return { ...payment, selected: id }
                } else {
                    return { ...payment, selected: id }
                }
            })

            setpaymentMethodChoices(updatedPlansPayment)
            // You can add your logic here to handle the radio button selection
        } else {
            // 
        }
    }

    const handlePaymentType = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const updatePaymentType = paymentGateway.map((payment: any) => {
            if (payment.id === id) {
                return { ...payment, selected: id }
            } else {
                return { ...payment, selected: id }
            }
        });
        setPaymentGateway(updatePaymentType);
        // console.log(updatePaymentType)
    }

    const handlePayment = (val: string) => {
        dispatch(updatePlanDetailsStates({ payment_method: val, validation: false }))
        setPaymentMethod(val)
    }

    // const amount = plan_duration === 'yearly' ? calcDiscount(planDetails?.amountYear, planDetails?.discount) : calcDiscount(planDetails?.amountMonth, planDetails?.discount);
    const amount = plan_duration === 'yearly' ? planDetails?.amountYear : planDetails?.amountMonth

    const formattedAmount: any = new Intl.NumberFormat().format(amount);

    return (
        <>
            {subscriptionData.length === 0 || loader ? (
                <Loader />
            ) : (
                <>
                    <div className="summary ">
                        <div className="container-fluid ">

                            <div className="row text-start justify-content-center ">

                                <SummaryForm
                                    subscriptionData={subscriptionData}
                                    handleChange={handleChange}
                                    formattedAmount={formattedAmount}
                                    plan_id={plan_id}
                                    plan_duration={plan_duration}
                                    orderSummary={ordersSummary}
                                    handleDeleteOrderSummary={handleDeleteOrderSummary}
                                    handleCoupon={handleCoupon}
                                    discount={discount}
                                    displaySubTotal={displaySubTotal}
                                    displayTotal={displayTotal}
                                    paymentGateway={paymentGateway}
                                    applyCoupon={applyCoupon}
                                    handlePaymentType={handlePaymentType}
                                    handlePayment={handlePayment}
                                    paymentMethod={paymentMethod}
                                    paymentMethodChoices={paymentMethodChoices}
                                    handleRadioChange={handleRadioChange}
                                    planDetails={planDetails}
                                />
                                <AddExtra subscriptionData={subscriptionData} handleChange={handleChange} plan_duration={plan_duration} plan_id={plan_id}/>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default PlanDetails