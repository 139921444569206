import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import { persistor, store } from './store/reducers/store'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query';
import { PlanSubscriptionContext } from './utility/planSubcription'
import ScrollToTop from './ScrollToTop'
import { AuthProvider } from './context/AuthContext'
// import RegisterLink from './components/buttons/registerBtn'
// import RootComponent from './NewRootComponents'

const queryClient = new QueryClient()
const App: React.FC = () => {
    const [plansSubscription, setPlansSubscription] = useState([])



    return (
        <>

            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AuthProvider>
                        <QueryClientProvider client={queryClient}>
                            <Router>
                                <PlanSubscriptionContext.Provider value={{ plansSubscription, setPlansSubscription }}>
                                    <ScrollToTop />
                                    <RootComponent />
                                    {/* <RegisterLink /> */}
                                </PlanSubscriptionContext.Provider>
                            </Router>
                        </QueryClientProvider>
                    </AuthProvider>
                </PersistGate>
            </Provider>
        </>
    )
}

export default App