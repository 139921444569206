import React, { createContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { AuthContextType, User } from '../types/auth'; // Ensure User and AuthContextType are typed
import { useAuth } from '../hooks/useAuth';

// Create the AuthContext
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Destructure auth state and actions from the custom hook
  const { user, isAuthenticated, userResponse, userResponseType, loading, login, logout, initializeSession, setRoutePage } = useAuth();

  // Initialize session when the component mounts
  useEffect(() => {
    initializeSession();
  }, []);  // Ensure `initializeSession` is memoized or stable

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({
    user,
    isAuthenticated,
    userResponse,
    userResponseType,
    login,
    logout,
    setRoutePage,
  }), [user, isAuthenticated, userResponse, userResponseType, login, logout, setRoutePage]);

  // Display loading state while session is being initialized
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
