import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import LogoutDropdown from '../../logoutDropdown'
import { jwtDecode } from 'jwt-decode'
import MobileNavbar from './mobileNavbar'
import { useAuthContext } from '../../../hooks/useAuthContext'

interface MyJwtPayload {
    username?: string
    name?: string
}

const Navbar: React.FC = () => {
    const [hamburger, setHamburger] = useState<boolean>(false)
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'))
    const {user} = useAuthContext();

    let decoded: MyJwtPayload | null = null
    if (token) {
        try {
            decoded = jwtDecode(token) as MyJwtPayload
        } catch (error) {
            console.error('Error decoding token:', error)
        }
    }

    const ToggleHamburger = () => {
        setHamburger(!hamburger)
        console.log(hamburger)
    }
    const navigate = useNavigate();
    // useEffect(() => {
    //     const handleLogout = () => {
    //         setToken(null); // Update token state to reflect logout
    //     };
    //     window.addEventListener('logout', handleLogout); // Listen for logout event

    //     return () => {
    //         window.removeEventListener('logout', handleLogout); // Clean up event listener
    //     };
    // }, []);
    return (
        <>
            <MobileNavbar />
            <nav className="navbar navbar-expand-md fixed-top bg-white navigation d-none d-lg-block">
                <div className="container ps-md-4 py-2">
                    <NavLink className="navbar-brand " to="/">
                        <img className=" img-fluid navigation--logo me-md-5 pe-md-5 " height={37} src="/assets/klakpad.svg" alt="logo" />
                    </NavLink>
                    {user ? (
                        <NavLink to="#" className=" text-end mt-2  ps-5 ms-5 d-block d-md-none">
                            <img src="/assets/avatar.svg" alt="" className="img-fluid" width={29} height={29} />
                        </NavLink>
                    ) : (
                        ''
                    )}
                    <button
                        onClick={ToggleHamburger}
                        className="navbar-toggler custom-toggler shadow-none border-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        {/* <span className="navbar-toggler-icon"></span> */}
                        <div className={hamburger ? 'animated-icon1 open' : 'animated-icon1'}>
                            <span />
                            <span />
                            <span />
                        </div>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 navigation__menu">
                            <li className="nav-item me-md-5  navigation__menu--item">
                                <NavLink
                                    to="/"
                                    className={({ isActive, isPending }) =>
                                        isPending ? 'pending' : isActive ? 'navigation__menu--active ' : 'navigation__menu--link'
                                    }
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item me-md-5   navigation__menu--item">
                                <NavLink
                                    to="products"
                                    className={({ isActive, isPending }) =>
                                        isPending ? 'pending' : isActive ? 'navigation__menu--active' : 'navigation__menu--link '
                                    }
                                >
                                    Products
                                </NavLink>
                            </li>

                            <li className="nav-item me-md-5  navigation__menu--item">
                                <NavLink
                                    to="pricing"
                                    className={({ isActive, isPending }) =>
                                        isPending ? 'pending' : isActive ? 'navigation__menu--active' : 'navigation__menu--link'
                                    }
                                >
                                    Pricing
                                </NavLink>
                            </li>
                        </ul>
                        <form className=" d-flex flex-md-row flex-column align-items-center ">
                            <button className="w-100 w-md-none mx-4 navigation__menu--btn d-none d-md-block" onClick={() => navigate('/download')}>
                                Download
                            </button>
                            {!user ? (
                                <button className="w-100 w-md-none mx-2 navigation__menu--auth d-none d-md-block" onClick={() => navigate('/login')}>
                                    Log in
                                </button>
                            ) : (
                                <>
                                    {/* <div className="ms-3 d-none d-md-block w-100 me-3">{decoded?.name}</div> */}
                                </>
                            )}
                            {!user ? (
                                <>
                                    
                                </>
                            ) : (
                                <div className=" me-5 pe-5">
                                    <LogoutDropdown />
                                </div>
                            )}
                            <NavLink to="/download" className="w-100 w-md-none mx-3 navigation__menu--btn d-md-none d-block">
                                Download
                            </NavLink>
                        
                            {!user ? (
                                <button className="w-100 w-md-none mx-3 navigation__menu--auth d-md-none d-block" onClick={() => navigate('/login')}>
                                    Log in
                                </button>
                            ) : (
                                <span className="mx-3 d-md-none d-block">User name </span>
                            )}
                        </form>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
