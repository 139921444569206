import React from 'react'
import { jwtDecode } from 'jwt-decode'
import { planDetailsStates } from '../../views/plans-details/planDetailsStates'
import { NavLink, useNavigate } from 'react-router-dom'
import { PostRequest } from '../../resources/api-request'
import { FiLogOut } from 'react-icons/fi'
import { useAuthContext } from '../../hooks/useAuthContext'
import { getDomainUrl, getSubdomainUrl } from '../../utility/functions'

interface MyJwtPayload {
    username?: string
    name?: string
}
const LogoutDropdown = () => {
    const token: any = localStorage.getItem('token') || ''
    const decoded: MyJwtPayload = token !== '' ? jwtDecode(token) : {}

    const navigate = useNavigate()
    const {  logout, user } = useAuthContext();
    // const logoutUser = async () => {
    //     const request = await PostRequest('logout', { token })
    //     if (request.data.message === 'Logged out successfully') {
    //         localStorage.removeItem('token')
    //         navigate('/')
    //     }
    // }
    // const logoutUser = async () => {
    //     const request = await PostRequest('logout', { token });
    //     if (request.data.message === 'Logged out successfully') {
    //         localStorage.removeItem('token');
    //         navigate('/');
    //         window.dispatchEvent(new Event('logout')); // Dispatch logout event
    //     }
    // };
   const d = getSubdomainUrl()
   console.log(d);
   

    return (
        <>
            <div className="dropdown show">
                <button className="btn " style={{ border: 'transparent' }} id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  
                <svg width="52" height="28" viewBox="0 0 52 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.434783" y="0.434783" width="27.1304" height="27.1304" rx="13.5652" fill="white"/>
                    <rect x="0.434783" y="0.434783" width="27.1304" height="27.1304" rx="13.5652" stroke="#394049" strokeWidth="0.869565"/>
                    <path d="M20.6663 21.5C20.6663 20.337 20.6663 19.7555 20.5228 19.2824C20.1996 18.217 19.366 17.3834 18.3006 17.0602C17.8275 16.9167 17.246 16.9167 16.083 16.9167H11.9163C10.7534 16.9167 10.1719 16.9167 9.69872 17.0602C8.63339 17.3834 7.79971 18.217 7.47654 19.2824C7.33301 19.7555 7.33301 20.337 7.33301 21.5M17.7497 10.25C17.7497 12.3211 16.0707 14 13.9997 14C11.9286 14 10.2497 12.3211 10.2497 10.25C10.2497 8.17893 11.9286 6.5 13.9997 6.5C16.0707 6.5 17.7497 8.17893 17.7497 10.25Z" stroke="#394049" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M37 11.5L42 16.5L47 11.5" stroke="#60666D" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  
                </button>

                <div
                    className="dropdown-menu shadow border-0"
                    style={{
                        minWidth: '300px'
                    }}
                >
                    <li>
                        <a className="dropdown-item" href="#">
                            <div className="d-flex justify-content-between">
                                <div className=" text-center">
                                    <img src="/assets/avatar.svg" className="img-fluid mt-2" alt="Profile" width={'35px'} height={'35px'} />
                                </div>
                                <div className="text-start" style={{

                                    position: 'relative',
                                    left: '-15%'
                                }}>
                                    <div className="fw-bold">{user?.name}</div>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '300'
                                        }}
                                    >
                                        {user?.username}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <hr className="dropdown-divider" />
                    <li className=''>
                        <a className="dropdown-item py-3" href={`${getSubdomainUrl()}/dashboard`}>
                            <div className="">
                                <img src="/assets/icons/sales-icon.svg" alt="" />
                                <span
                                    className="ps-2"
                                    style={{
                                        fontSize: '14px'
                                    }}
                                >
                                    My Dashboard
                                </span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    {!user ? (
                        <>
                           
                        </>
                    ) : (
                        <>
                            <li>
                                <div className="dropdown-item py-3" onClick={logout}>
                                    <div className="">
                                        {' '}
                                        <FiLogOut color="#C90000" fontSize={18} />{' '}
                                        <span className="" style={{ color: '#C90000', fontSize: '14px' }}>
                                        Log out
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
export default LogoutDropdown
