/**
 * This function can be used anywhere in the app to greet the user
 * @param userName The user's first name
 * @returns A kind greeting message
 */
export const sayHello = (userName: string): string => {
    return 'Welcome ' + userName + '!'
}

export const convertToCamelCase = (phrase: string) => {
    const words = phrase.split(' ')
    const camelCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    return camelCaseWords.join(' ') // Join words with spaces
}

export const format = (num: any, dec: any = 0, acr = "") => {
    return `${num.toFixed(dec).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${acr}`;
};

export const getUrl = () => {
     // URLs based on environment variables
    const mainApiUrl = process.env.REACT_APP_MAIN_API_URL;
    console.log({mainApiUrl,}, "incoming url");
    return mainApiUrl;
}

export const getSubdomainUrl = () => {
    // URLs based on environment variables
   const accountSubdomainUrl = process.env.REACT_APP_ACCOUNT_SUBDOMAIN_URL;
   console.log({ accountSubdomainUrl}, "incoming url");
   return accountSubdomainUrl;
}

export const getDomainUrl = () => {
    // URLs based on environment variables
   const domainUrl = process.env.REACT_APP_ACCOUNT_DOMAIN_URL;
   console.log({ domainUrl}, "incoming url");
   return domainUrl;
}