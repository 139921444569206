import React, { useEffect } from 'react'
import { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { postRequest } from '../../utility/apiRequest'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { jwtDecode } from 'jwt-decode'
import { useAuth } from '../../hooks/useAuth'
import { useAuthContext } from '../../hooks/useAuthContext'


interface MatchParams {
    page?: string
    plan_id?: string
    plan_duration?: string
}

interface MyJwtPayload {
    verification_status: any
    subscription_status: string
}
const SignIn: React.FC = () => {
    const [hideorshowPassword, setHideorShowPassword] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    // const [userResponse, setUserResponse] = useState<string>('')
    // const [userResponseType, setUserResponseType] = useState<boolean>(false)
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
    const navigate = useNavigate()
    const location = useLocation()
    let decoded: MyJwtPayload | null = null;
    if (token && token !== '') {
        decoded = jwtDecode<MyJwtPayload>(token);
    }
    // const decoded: MyJwtPayload = jwtDecode(token)
    const params = new URLSearchParams(location.search)
    const page = params.get('page')
    const plan_id = params.get('plan_id')
    const plan_duration = params.get('plan_duration')
    // console.log({decoded})

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email or Username is required'),
        password: Yup.string().required('Password is required')
    })
    const { userResponse, userResponseType, login,  setRoutePage } = useAuthContext();

    useEffect(() => {
        if (page) setRoutePage(page);
    }, [page])

    useEffect(() => {
        // console.log("user changed", userResponse)
        setLoading(false);
    }, [userResponse])


    const handleSubmitForm = async (e: any, { resetForm }: any) => {
        setLoading(true)
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        await login(e);
    }
    const handleHideOrShowPassword = () => {
        setHideorShowPassword(!hideorshowPassword)
    }
    return (
        <>
            <Helmet>
                <title>Klakpad - Login </title>
                <meta name="description" content="Description of the page" />
                <meta property="og:title" content="Klakpad Login" />
                <meta property="og:description" content="Take your business to the next level" />
                <meta property="og:image" content="URL_to_image.jpg" />
            </Helmet>
            <div className="signup animate__animated ">
                <div className="container-fluid p-lg-0">
                    <div className="row align-items-center justify-content-center ">
                        <div className="col-md-6 col-sm-12 signup__col" >
                            <div className="ms-lg-4 ms-xxl-5" >
                                <NavLink to="/" className="signup__col--link ps-lg-4">
                                    {' '}
                                    <img src="/assets/klakpad.svg" srcSet="" className="img-fluid signin__col--logo ms-lg-5 " alt="Logo" width={120} />
                                </NavLink>
                            </div>
                            <Formik initialValues={{ email: '', password: '' }} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
                                {(props) => {
                                    const { errors, dirty, touched, isValid, values } = props
                                    return (
                                        <>
                                            <div className="row  align-items-center justify-content-center py-5">
                                                <div className="col-md-8 pb-5"></div>
                                            </div>
                                            <Form>
                                                <div className="row  align-items-center justify-content-center">
                                                    <div className="col-md-8">
                                                        <h3 className="signup__col--title pb-5">Log in</h3>
                                                    </div>
                                                </div>

                                                <div className="row  align-items-center justify-content-center">
                                                    <div className="col-md-7">
                                                        {userResponse && (
                                                            <p className={userResponseType ? 'alert alert-success' : 'alert alert-danger'}>{userResponse}</p>
                                                        )}
                                                    </div>

                                                    <div className="col-md-8 mb-4">
                                                        <label htmlFor="name" className="signup__col--label">
                                                            Email address
                                                        </label>
                                                        <Field
                                                            placeholder="Enter your email address or username"
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            className={
                                                                touched.email && errors.email
                                                                    ? 'form-control  px-3  signup__col--inpisIvalid shadow-none'
                                                                    : 'form-control  px-3 signup__col--inp shadow-none'
                                                            }
                                                        />
                                                        <ErrorMessage name="email" component="div" className="error text-danger signup__col--label" />
                                                    </div>

                                                    <div className="col-md-8 position-relative mb-4">
                                                        <label htmlFor="name" className="signup__col--label">
                                                            Password
                                                        </label>
                                                        <Field
                                                            placeholder="Enter your password"
                                                            type={hideorshowPassword == false ? 'password' : 'text'}
                                                            name="password"
                                                            id="password"
                                                            className={
                                                                touched.password && errors.password
                                                                    ? 'form-control px-3  signup__col--inpisIvalid shadow-none'
                                                                    : 'form-control  px-3 signup__col--inp shadow-none'
                                                            }
                                                        />
                                                        <ErrorMessage name="password" component="div" className="error text-danger signup__col--label" />

                                                        <i
                                                            className={
                                                                hideorshowPassword
                                                                    ? `fa-regular fa-eye signup__col--icon`
                                                                    : `fa-regular fa-eye-slash signup__col--icon`
                                                            }
                                                            onClick={handleHideOrShowPassword}
                                                        />
                                                        <p className="text-end pt-2 mt-1">
                                                            {' '}
                                                            <NavLink to="/forgot-password" className="signup__col--link">
                                                                Forgot password?
                                                            </NavLink>{' '}
                                                        </p>
                                                    </div>

                                                    <div className="col-md-8 mb-5">
                                                        {loading ? (
                                                            <button type="button" className="link btn fw-bold py-3 px-5 me-0 signup__col--btn " disabled>
                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className={!(dirty && touched) ? 'disabled-btn signup__col--btn' : 'signup__col--btn'}
                                                                type="submit"
                                                            >
                                                                Log in
                                                            </button>
                                                        )}
                                                    </div>


                                                    <div className="col-md-8  text-center">
                                                        <p className="signup__col--label">
                                                            Don't have an account?{' '}
                                                            <NavLink
                                                                to={`/signup`}
                                                                // onClick={()=>logout}
                                                                // to={`/signup?page=pricing&id=${2}&duration=${'yearly'}`}
                                                                style={{ color: '#2C4A8B', textDecoration: 'none', fontWeight: '600', fontSize: '14px' }}
                                                            >
                                                                Sign up
                                                            </NavLink>
                                                            
                                                        </p>
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                }}
                            </Formik>
                        </div>
                        <div
                            className="col-md-6 d-none d-lg-block"
                            style={{
                                backgroundImage: 'url(/assets/signin.png)',
                                height: '100vh',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}
                        ></div>
                    </div>
                </div>
            </div>
            {/* <button onClick={() => logout()}>Logout</button> */}
        </>
    )
}
SignIn.propTypes = {
    errors: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired
}
export default SignIn
