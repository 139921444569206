import axios from 'axios';
import { getCookie, clearTokens } from '../utility/storage';
import { refreshToken } from './Auth';
import { getUrl } from '../utility/functions';

const apiClient = axios.create({
  baseURL: getUrl(),
  // timeout: 10000,
  withCredentials: true, // Include cookies
});

// Request interceptor: Add access token to headers
// apiClient.interceptors.request.use(
//   (config) => {
//     const token = getCookie('accessToken');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// Response interceptor: Handle token expiration
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 errors (unauthorized) due to token expiration
    if (error.response?.status === 401 && error.response?.data?.data?.action === 'refresh' && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the token
        await refreshToken();

        // Retry the original request with the new token
        return apiClient(originalRequest);
      } catch (refreshError) {
        // Refresh failed; clear tokens and redirect to login
        // clearTokens();
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
