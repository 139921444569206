import apiClient from '../appClient';
import { setCookie, clearTokens } from '../../utility/storage';
import { User } from '../../types/auth';

// Define the LoginResponse interface
interface LoginResponse {
  message: string; // Include the message field
  data: User; // Data field contains the User object
}

// Login and save tokens
export const login = async (credentials: { email: string; password: string }): Promise<User> => {
  // Make the API request
  const response = await apiClient.post<LoginResponse>('/auth/login', { ...credentials,username:credentials.email, origin: 3 });
  console.log(response, "incoming");

  // Extract and return the User data from the response
  return response.data.data;
};

// Logout and clear tokens
export const logout = async (): Promise<void> => {
  await apiClient.post('auth/logout');
};

export const fetchSession = async (): Promise< User > => {
  const { data } = await apiClient.get('/auth/session'); // Backend endpoint to validate the session
  return data.data;
};


// Validate the access token
export const validateToken = async (): Promise<boolean> => {
  try {
    await apiClient.get('/auth/validate');
    return true;
  } catch {
    return false;
  }
};

// Refresh tokens
export const refreshToken = async (): Promise<void> => {
  await apiClient.post<{ accessToken: string }>('/auth/refresh');
};
