// useAuth.ts
import { useState } from 'react';
import { fetchSession, login as loginService, logout as logoutService } from '../services/Auth';
import { User } from '../types/auth';
import { getDomainUrl, getSubdomainUrl } from '../utility/functions';

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userResponse, setUserResponse] = useState<string>('')
  const [userResponseType, setUserResponseType] = useState<boolean>(false)
  const [redirectPage, setRedirectPage] = useState<string | null>(null);

  // Load session data on initial render
  const initializeSession = async () => {
    try {
      const sessionData = await fetchSession();
      if (sessionData) {
        setUser(sessionData);
        setIsAuthenticated(true);
      }
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const resetUserResponse = () => {
    setTimeout(() => {
      setUserResponse('');
      setUserResponseType(false);  // Reset the type of response (success or error)
    }, 5000); // Reset after 5 seconds
  };
  // Set route page function (example)
  const setRoutePage = (redirectPage: string | null) => {
    setRedirectPage(redirectPage);
  };

  // 1. Redirect Function
  const redirectTo = (page: string | null): void => {
    if (page === null) {
      // Handle the null case if necessary, defaulting to subdomain dashboard
      window.location.href = `${getSubdomainUrl()}/dashboard`;
      return;
    }

    // Construct the base URL once
    const baseUrl = page === 'subscription' || page === 'verifypage'
      ? getDomainUrl()
      : getSubdomainUrl();

    // Redirect the user
    window.location.href = `${baseUrl}/${page === 'subscription' || page === 'verifypage' ? page : 'dashboard'}`;
  };


  // 2. User State Management Function
  const setUserState = (loggedInUser: User): void => {
    // Assuming `setUser`, `setIsAuthenticated`, and `setUserResponse` are defined elsewhere
    setUser(loggedInUser);
    setIsAuthenticated(true);
    setUserResponse("Login Successful");
    setUserResponseType(true);
    resetUserResponse();  // Reset the response after 5 seconds
  };

  // 3. Error Handling Logic
  const handleLoginError = (error: any): void => {
    const errorMessage = error?.message || 'An error occurred during login';
    console.error("Login error:", errorMessage);
    setUser(null);
    setUserResponse(errorMessage);
    setUserResponseType(false);
    resetUserResponse();  // Reset the response after 5 seconds
  };

  // 4. Main Login Function (Refactored to follow SRP)
  const login = async (credentials: { email: string; password: string }): Promise<void> => {
    try {
      const loggedInUser = await loginService(credentials); // Authenticate user
      setUserState(loggedInUser); // Update state with user data
      const redirectPage = getSubdomainUrl(); // Get the redirect URL
      redirectTo(redirectPage ?? null); // Redirect user to the subdomain
    } catch (error) {
      handleLoginError(error); // Handle login failure
      throw error; // Rethrow error after handling it
    }
  };
  // const login = async (credentials: { email: string; password: string }) => {
  //   try {
  //     const loggedInUser = await loginService(credentials);
  //     setUser(loggedInUser);
  //     setIsAuthenticated(true);
  //     setUserResponse("Login Successful");
  //     setUserResponseType(true);
  //     resetUserResponse();  // Reset the response after 5 seconds
  //     window.location.href = `${getSubdomainUrl()}/dashboard`;
  //   } catch (error: any) {
  //     const errorMessage = error?.response?.data?.message || 'An error occurred during login';
  //     console.error("Login error:", errorMessage);
  //     setUser(null);
  //     setUserResponse(errorMessage);
  //     setUserResponseType(false);
  //     resetUserResponse();  // Reset the response after 5 seconds
  //     throw new Error(errorMessage);
  //   }
  // };


  const logout = async () => {
    try {
      await logoutService();
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };


  return { user, userResponse, userResponseType, isAuthenticated, loading, login, logout, initializeSession, setRoutePage };
};
