import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import LogoutDropdown from '../../logoutDropdown'
import { useAuthContext } from '../../../hooks/useAuthContext'

const MobileNavbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const token: any = localStorage.getItem('token') || ''
    const {user} = useAuthContext();

    const toggleSidebar = () => {
        setIsOpen(!isOpen)
    }
    const Sidebar = ({ toggleSidebar }: any) => {
        return (
            <div className="navigation__sidebar">
                <div className="navigation__sidebar-header">
                    <NavLink className="navbar-brand " to="/">
                        <svg width="99" height="24" viewBox="0 0 99 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_7267_5544)">
                                <path
                                    d="M19.9331 1.5523C19.9331 1.78459 19.8736 2.00062 19.7706 2.1911L19.7408 2.24452L14.3577 10.6673C14.3577 10.6673 14.1265 11.0599 14.1174 11.0692C13.8816 11.341 13.536 11.492 13.1767 11.492H12.8929L1.30721 11.4966H1.0257C0.945591 11.4873 0.867773 11.4687 0.792244 11.4455C0.313895 11.2852 -0.00195312 10.8183 -0.00195312 10.3073V1.38273C0.080442 0.75787 0.593123 0.270062 1.22253 0.242188H18.6994C19.3861 0.272385 19.9331 0.848463 19.9331 1.5523Z"
                                    fill="#2C4A8B"
                                />
                                <path
                                    d="M19.9331 22.416C19.9331 22.1837 19.8736 21.9677 19.7706 21.7772L19.7408 21.7238L14.3577 13.301C14.3577 13.301 14.1265 12.9084 14.1174 12.8991C13.8816 12.6273 13.536 12.4763 13.1767 12.4763H12.8929L1.30721 12.4717H1.0257C0.945591 12.481 0.867773 12.4996 0.792244 12.5228C0.313895 12.6831 -0.00195312 13.15 -0.00195312 13.661V22.5832C0.0781532 23.2081 0.590834 23.6959 1.22024 23.7238H18.6972C19.3838 23.6936 19.9308 23.1175 19.9308 22.4137L19.9331 22.416Z"
                                    fill="#6DD4F7"
                                />
                                <path
                                    d="M29.2579 13.4909H27.9533V19.7H25.9941V5.49549H27.9533V11.6419H29.3197C29.8095 11.6419 30.2718 11.4165 30.5785 11.0309L34.9889 5.49316H37.328L31.6107 12.5943L37.5272 19.6954H35.1492L30.4961 14.0739C30.1894 13.7023 29.734 13.4862 29.2556 13.4862L29.2579 13.4909Z"
                                    fill="#111A24"
                                />
                                <path d="M39.5273 4.84863H41.4064V19.6989H39.5273V4.84863Z" fill="#111A24" />
                                <path
                                    d="M57.8251 15.1344H57.0744V19.6989H55.1953V4.84863H57.0744V13.4503H57.9235C58.3996 13.4503 58.8505 13.2389 59.1571 12.8696L62.0501 9.4108H64.3412L60.242 14.3005L64.3297 19.6966H62.1302L59.0976 15.7616C58.7909 15.3644 58.3195 15.1298 57.8228 15.1298L57.8251 15.1344Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M71.3872 19.847C69.7072 19.847 68.2882 19.0363 67.7091 17.7378V23.7588H65.8301V9.4149H67.7091V11.3777C68.2882 10.0792 69.7072 9.26855 71.3872 9.26855C74.1451 9.26855 76.1455 11.3847 76.1455 14.5694C76.1455 17.7541 74.1474 19.8493 71.3872 19.8493V19.847ZM73.2868 11.9306C72.6872 11.2407 71.8884 10.8969 70.9088 10.8969C68.9291 10.8969 67.6313 12.3975 67.6313 14.5694C67.6313 15.6449 67.9312 16.516 68.5308 17.2059C69.1305 17.8749 69.9292 18.221 70.9088 18.221C71.8884 18.221 72.6872 17.8772 73.2868 17.2059C73.9071 16.516 74.2069 15.6426 74.2069 14.5694C74.2069 13.4962 73.9071 12.6019 73.2868 11.9329V11.9306Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M87.5525 9.54939V19.8352H85.6734V18.0094C85.0943 19.3079 83.6753 20.1186 82.016 20.1186C79.258 20.1186 77.2188 17.8654 77.2188 14.7016C77.2188 11.5378 79.258 9.26367 82.016 9.26367C83.6753 9.26367 85.0943 10.0744 85.6734 11.3729V9.54706H87.5525V9.54939ZM82.476 11.0314C81.4964 11.0314 80.6977 11.3752 80.0774 12.0651C79.4778 12.7341 79.1779 13.6284 79.1779 14.7016C79.1779 15.7748 79.4778 16.6482 80.0774 17.3381C80.6977 18.0071 81.4964 18.3532 82.476 18.3532C83.4556 18.3532 84.2544 18.0094 84.854 17.3381C85.4537 16.6482 85.7535 15.7748 85.7535 14.7016C85.7535 12.5297 84.4535 11.0291 82.476 11.0291V11.0314Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M53.4568 9.54939V19.8352H51.5777V18.0094C50.9986 19.3079 49.5796 20.1186 47.9203 20.1186C45.1623 20.1186 43.123 17.8654 43.123 14.7016C43.123 11.5378 45.1623 9.26367 47.9203 9.26367C49.5796 9.26367 50.9986 10.0744 51.5777 11.3729V9.54706H53.4568V9.54939ZM48.3803 11.0314C47.4007 11.0314 46.602 11.3752 45.9817 12.0651C45.382 12.7341 45.0822 13.6284 45.0822 14.7016C45.0822 15.7748 45.382 16.6482 45.9817 17.3381C46.602 18.0071 47.4007 18.3532 48.3803 18.3532C49.3599 18.3532 50.1587 18.0094 50.7583 17.3381C51.358 16.6482 51.6578 15.7748 51.6578 14.7016C51.6578 12.5297 50.3578 11.0291 48.3803 11.0291V11.0314Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M99.1833 4.84803V19.6983H97.3043V17.8725C96.7252 19.171 95.2856 19.9817 93.6262 19.9817C90.8866 19.9817 88.8496 17.7285 88.8496 14.5647C88.8496 11.4009 90.8889 9.1268 93.6262 9.1268C95.2856 9.1268 96.7252 9.93749 97.3043 11.236V4.8457H99.1833V4.84803ZM94.1069 10.8945C93.1273 10.8945 92.3285 11.2383 91.7083 11.9282C91.1086 12.5972 90.8088 13.4915 90.8088 14.5647C90.8088 15.6379 91.1086 16.5113 91.7083 17.2012C92.3285 17.8702 93.1273 18.2163 94.1069 18.2163C95.0865 18.2163 95.8852 17.8725 96.4849 17.2012C97.0845 16.5113 97.3844 15.6379 97.3844 14.5647C97.3844 12.3928 96.0844 10.8922 94.1069 10.8922V10.8945Z"
                                    fill="#111A24"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_7267_5544">
                                    <rect width="99" height="23.5124" fill="white" transform="translate(0 0.244141)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </NavLink>
                    <div className="close-icon" onClick={toggleSidebar}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke="#111A24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className="sidebar-content">
                    <ul className="navbar-nav mt-5">
                        <NavLink to="/" className="text-decoration-none" onClick={toggleSidebar}>
                            <li className="nav-item navigation__sidebar--link ">Home</li>
                        </NavLink>
                        <NavLink to="/products" className="text-decoration-none" onClick={toggleSidebar}>
                            <li className="nav-item navigation__sidebar--link ">Products</li>
                        </NavLink>
                        <NavLink to="/pricing" className="text-decoration-none" onClick={toggleSidebar}>
                            <li className="nav-item navigation__sidebar--link ">Pricing</li>
                        </NavLink>
                    </ul>
                    <NavLink to="/download">
                        <button className="navigation__sidebar--btn">Download</button>
                    </NavLink>
                    {!token ? (
                        <NavLink to="/login">
                            <button className="navigation__sidebar--auth">Log in</button>
                        </NavLink>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="d-md-block d-lg-none">
                <div className="navigation__mobile shadow-none">
                    <div className="menu-icon" onClick={toggleSidebar}>
                        <div className="menu-line"></div>
                        <div className="menu-line"></div>
                        <div className="menu-line"></div>
                    </div>
                    <NavLink className="navbar-brand " to="/">
                        <svg width="99" height="24" viewBox="0 0 99 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_7267_5544)">
                                <path
                                    d="M19.9331 1.5523C19.9331 1.78459 19.8736 2.00062 19.7706 2.1911L19.7408 2.24452L14.3577 10.6673C14.3577 10.6673 14.1265 11.0599 14.1174 11.0692C13.8816 11.341 13.536 11.492 13.1767 11.492H12.8929L1.30721 11.4966H1.0257C0.945591 11.4873 0.867773 11.4687 0.792244 11.4455C0.313895 11.2852 -0.00195312 10.8183 -0.00195312 10.3073V1.38273C0.080442 0.75787 0.593123 0.270062 1.22253 0.242188H18.6994C19.3861 0.272385 19.9331 0.848463 19.9331 1.5523Z"
                                    fill="#2C4A8B"
                                />
                                <path
                                    d="M19.9331 22.416C19.9331 22.1837 19.8736 21.9677 19.7706 21.7772L19.7408 21.7238L14.3577 13.301C14.3577 13.301 14.1265 12.9084 14.1174 12.8991C13.8816 12.6273 13.536 12.4763 13.1767 12.4763H12.8929L1.30721 12.4717H1.0257C0.945591 12.481 0.867773 12.4996 0.792244 12.5228C0.313895 12.6831 -0.00195312 13.15 -0.00195312 13.661V22.5832C0.0781532 23.2081 0.590834 23.6959 1.22024 23.7238H18.6972C19.3838 23.6936 19.9308 23.1175 19.9308 22.4137L19.9331 22.416Z"
                                    fill="#6DD4F7"
                                />
                                <path
                                    d="M29.2579 13.4909H27.9533V19.7H25.9941V5.49549H27.9533V11.6419H29.3197C29.8095 11.6419 30.2718 11.4165 30.5785 11.0309L34.9889 5.49316H37.328L31.6107 12.5943L37.5272 19.6954H35.1492L30.4961 14.0739C30.1894 13.7023 29.734 13.4862 29.2556 13.4862L29.2579 13.4909Z"
                                    fill="#111A24"
                                />
                                <path d="M39.5273 4.84863H41.4064V19.6989H39.5273V4.84863Z" fill="#111A24" />
                                <path
                                    d="M57.8251 15.1344H57.0744V19.6989H55.1953V4.84863H57.0744V13.4503H57.9235C58.3996 13.4503 58.8505 13.2389 59.1571 12.8696L62.0501 9.4108H64.3412L60.242 14.3005L64.3297 19.6966H62.1302L59.0976 15.7616C58.7909 15.3644 58.3195 15.1298 57.8228 15.1298L57.8251 15.1344Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M71.3872 19.847C69.7072 19.847 68.2882 19.0363 67.7091 17.7378V23.7588H65.8301V9.4149H67.7091V11.3777C68.2882 10.0792 69.7072 9.26855 71.3872 9.26855C74.1451 9.26855 76.1455 11.3847 76.1455 14.5694C76.1455 17.7541 74.1474 19.8493 71.3872 19.8493V19.847ZM73.2868 11.9306C72.6872 11.2407 71.8884 10.8969 70.9088 10.8969C68.9291 10.8969 67.6313 12.3975 67.6313 14.5694C67.6313 15.6449 67.9312 16.516 68.5308 17.2059C69.1305 17.8749 69.9292 18.221 70.9088 18.221C71.8884 18.221 72.6872 17.8772 73.2868 17.2059C73.9071 16.516 74.2069 15.6426 74.2069 14.5694C74.2069 13.4962 73.9071 12.6019 73.2868 11.9329V11.9306Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M87.5525 9.54939V19.8352H85.6734V18.0094C85.0943 19.3079 83.6753 20.1186 82.016 20.1186C79.258 20.1186 77.2188 17.8654 77.2188 14.7016C77.2188 11.5378 79.258 9.26367 82.016 9.26367C83.6753 9.26367 85.0943 10.0744 85.6734 11.3729V9.54706H87.5525V9.54939ZM82.476 11.0314C81.4964 11.0314 80.6977 11.3752 80.0774 12.0651C79.4778 12.7341 79.1779 13.6284 79.1779 14.7016C79.1779 15.7748 79.4778 16.6482 80.0774 17.3381C80.6977 18.0071 81.4964 18.3532 82.476 18.3532C83.4556 18.3532 84.2544 18.0094 84.854 17.3381C85.4537 16.6482 85.7535 15.7748 85.7535 14.7016C85.7535 12.5297 84.4535 11.0291 82.476 11.0291V11.0314Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M53.4568 9.54939V19.8352H51.5777V18.0094C50.9986 19.3079 49.5796 20.1186 47.9203 20.1186C45.1623 20.1186 43.123 17.8654 43.123 14.7016C43.123 11.5378 45.1623 9.26367 47.9203 9.26367C49.5796 9.26367 50.9986 10.0744 51.5777 11.3729V9.54706H53.4568V9.54939ZM48.3803 11.0314C47.4007 11.0314 46.602 11.3752 45.9817 12.0651C45.382 12.7341 45.0822 13.6284 45.0822 14.7016C45.0822 15.7748 45.382 16.6482 45.9817 17.3381C46.602 18.0071 47.4007 18.3532 48.3803 18.3532C49.3599 18.3532 50.1587 18.0094 50.7583 17.3381C51.358 16.6482 51.6578 15.7748 51.6578 14.7016C51.6578 12.5297 50.3578 11.0291 48.3803 11.0291V11.0314Z"
                                    fill="#111A24"
                                />
                                <path
                                    d="M99.1833 4.84803V19.6983H97.3043V17.8725C96.7252 19.171 95.2856 19.9817 93.6262 19.9817C90.8866 19.9817 88.8496 17.7285 88.8496 14.5647C88.8496 11.4009 90.8889 9.1268 93.6262 9.1268C95.2856 9.1268 96.7252 9.93749 97.3043 11.236V4.8457H99.1833V4.84803ZM94.1069 10.8945C93.1273 10.8945 92.3285 11.2383 91.7083 11.9282C91.1086 12.5972 90.8088 13.4915 90.8088 14.5647C90.8088 15.6379 91.1086 16.5113 91.7083 17.2012C92.3285 17.8702 93.1273 18.2163 94.1069 18.2163C95.0865 18.2163 95.8852 17.8725 96.4849 17.2012C97.0845 16.5113 97.3844 15.6379 97.3844 14.5647C97.3844 12.3928 96.0844 10.8922 94.1069 10.8922V10.8945Z"
                                    fill="#111A24"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_7267_5544">
                                    <rect width="99" height="23.5124" fill="white" transform="translate(0 0.244141)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </NavLink>
                    <div className="profile-icon">
                        {user ? (
                            <LogoutDropdown />
                        ) : (
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.377778" y="0.377778" width="29.2444" height="29.2444" rx="14.6222" fill="white" />
                                <rect x="0.377778" y="0.377778" width="29.2444" height="29.2444" rx="14.6222" stroke="#B0B3B6" strokeWidth="0.755556" />
                                <path
                                    d="M14.9993 17.9167H11.2494C10.0864 17.9167 9.50489 17.9167 9.03173 18.0602C7.96639 18.3834 7.13272 19.217 6.80955 20.2824C6.66602 20.7555 6.66602 21.337 6.66602 22.5M20.8327 22.5V17.5M18.3327 20H23.3327M17.0827 11.25C17.0827 13.3211 15.4037 15 13.3327 15C11.2616 15 9.58268 13.3211 9.58268 11.25C9.58268 9.17893 11.2616 7.5 13.3327 7.5C15.4037 7.5 17.0827 9.17893 17.0827 11.25Z"
                                    stroke="#B0B3B6"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                    </div>
                    {isOpen && <Sidebar toggleSidebar={toggleSidebar} />}
                    {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}
                </div>
            </div>
        </>
    )
}

export default MobileNavbar
